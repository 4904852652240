'use client';

import { useRouter, useSearchParams } from 'next/navigation';
import React, { Suspense, useEffect, useState } from 'react';
import { encodePassphrase, generateRoomId, randomString } from '@/lib/client-utils';
import { PageClientImpl } from '@/lib/room';
import { isVideoCodec } from '@/lib/types';
import styles from '../styles/Home.module.css';

function Tabs(props: React.PropsWithChildren<{}>) {
  const searchParams = useSearchParams();
  const tabIndex = searchParams?.get('tab') === 'custom' ? 1 : 0;

  const router = useRouter();
  function onTabSelected(index: number) {
    const tab = index === 1 ? 'custom' : 'demo';
    router.push(`/?tab=${tab}`);
  }

  let tabs = React.Children.map(props.children, (child, index) => {
    return (
      <button
        className="lk-button"
        onClick={() => {
          if (onTabSelected) {
            onTabSelected(index);
          }
        }}
        aria-pressed={tabIndex === index}
      >
        {/* @ts-ignore */}
        {child?.props.label}
      </button>
    );
  });

  return (
    <div className={styles.tabContainer}>
      <div className={styles.tabSelect}>{tabs}</div>
      {/* @ts-ignore */}
      {props.children[tabIndex]}
    </div>
  );
}

function DemoMeetingTab(props: { label: string }) {
  const router = useRouter();
  const [e2ee, setE2ee] = useState(false);
  const [sharedPassphrase, setSharedPassphrase] = useState(randomString(64));
  const startMeeting = () => {
    if (e2ee) {
      router.push(`/?pageId=${generateRoomId()}#${encodePassphrase(sharedPassphrase)}`);
    } else {
      router.push(`/?pageId=${generateRoomId()}`);
    }
  };
  return (
    <div className={styles.tabContent}>
      <img src="/images/logo.svg" alt="Beam Meet" width="98" style={{margin: "auto"}} />
      <button className={styles.startButton} onClick={startMeeting}>
        Start Meeting
      </button>
      <p style={{color:  "#000000", lineHeight: 1.6}}>Connect, collaborate, and celebrate from anywhere with Beam Meet</p>
      {/* <div style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
        <div style={{ display: 'flex', flexDirection: 'row', gap: '1rem' }}>
          <input
            id="use-e2ee"
            type="checkbox"
            checked={e2ee}
            onChange={(ev) => setE2ee(ev.target.checked)}
          ></input>
          <label htmlFor="use-e2ee">Enable end-to-end encryption</label>
        </div>
        {e2ee && (
          <div style={{ display: 'flex', flexDirection: 'row', gap: '1rem', alignItems: "center" }}>
            <label htmlFor="passphrase">Passphrase</label>
            <input
              id="passphrase"
              type="password"
              value={sharedPassphrase}
              className={styles.passwordInput}
              onChange={(ev) => setSharedPassphrase(ev.target.value)}
            />
          </div>
        )}
      </div> */}
    </div>
  );
}

export default function Page({
  params,
  searchParams,
}: {
  params: { pageId: string };
  searchParams: {
    // FIXME: We should not allow values for regions if in playground mode.
    region?: string;
    o?: string;
    u?: string;
    hq?: string;
    codec?: string;
    pageId?: string;
    metadata?: string;
    viewer?: string
  };
}) {

  useEffect(() => {
  }, [params])
  const codec =
    typeof searchParams.codec === 'string' && isVideoCodec(searchParams.codec)
      ? searchParams.codec
      : 'vp9';
  const hq = searchParams.hq === 'true' ? true : false;

  if (searchParams.pageId) {
    return (
      <PageClientImpl
        roomName={searchParams.pageId}
        region={searchParams.region}
        hq={hq}
        codec={codec}
        userId={searchParams.u}
        partitionId={searchParams.o}
        randomParticipantPostfix={searchParams.metadata || ''}
        viewer={searchParams.viewer}
      />
    );
  }

  return (
    <>
      <header>
        <img src="/images/logo.svg" alt="Beam Meet" />
      </header>
      <main className={styles.main} data-lk-theme="default">
        <Suspense fallback="Loading">
          <DemoMeetingTab label="Demo" />
        </Suspense>
      </main>
      {/* <footer data-lk-theme="default">
        ©2025 All Rights Reserved - Beam Meet
      </footer> */}
    </>
  );
}
