'use client';

import { decodePassphrase } from '@/lib/client-utils';
import { DebugMode } from '@/lib/Debug';
import { RecordingIndicator } from '@/lib/RecordingIndicator';
import { SettingsMenu } from '@/lib/SettingsMenu';
import { ConnectionDetails } from '@/lib/types';
import {
  formatChatMessageLinks,
  LiveKitRoom,
  LocalUserChoices,
  PreJoin,
  VideoConference
} from '@livekit/components-react';
import {
  ExternalE2EEKeyProvider,
  RoomOptions,
  VideoCodec,
  VideoPresets,
  Room,
  DeviceUnsupportedError,
  RoomConnectOptions,
} from 'livekit-client';
import { useRouter } from 'next/navigation';
import React from 'react';
import moment from 'moment';
import { profile } from 'console';
import {CustomVideoConference} from './CustomVideoConference'

const CONN_DETAILS_ENDPOINT =
  process.env.NEXT_PUBLIC_CONN_DETAILS_ENDPOINT ?? '/api/connection-details';
const SHOW_SETTINGS_MENU = process.env.NEXT_PUBLIC_SHOW_SETTINGS_MENU == 'true';

export function PageClientImpl(props: {
  roomName: string;
  region?: string;
  hq: boolean;
  codec: VideoCodec;
  userId?: string;
  partitionId?: string;
  randomParticipantPostfix?: string;
  viewer?: string;
}) {
  const [preJoinChoices, setPreJoinChoices] = React.useState<LocalUserChoices | undefined>(
    undefined,
  );

  const [roomTitle, setRoomTitle] = React.useState("");
  const [roomUser, setRoomUser] = React.useState("");

  const preJoinDefaults = React.useMemo(() => {
    return {
      username: roomUser,
      videoEnabled: true,
      audioEnabled: true,
    };
  }, [roomUser]);

  const [connectionDetails, setConnectionDetails] = React.useState<ConnectionDetails | undefined>(
    undefined,
  );

  const handlePreJoinSubmit = React.useCallback(async (values: LocalUserChoices) => {
    setPreJoinChoices(values);
    const url = new URL(CONN_DETAILS_ENDPOINT, window.location.origin);
    url.searchParams.append('roomName', props.roomName);
    url.searchParams.append('participantName', values.username);
    url.searchParams.append('randomParticipantPostfix', props.randomParticipantPostfix || '');
    if (props.region) {
      url.searchParams.append('region', props.region);
    }
    if (props.viewer) {
      url.searchParams.append('viewer', props.viewer);
    }
    const connectionDetailsResp = await fetch(url.toString());
    const connectionDetailsData = await connectionDetailsResp.json();
    setConnectionDetails(connectionDetailsData);
  }, []);
  const handlePreJoinError = React.useCallback((e: any) => console.error(e), []);

  const defaultUsername = props.viewer === 'true'? "Guest": "Username"

  React.useEffect(() => {
    const fetchData = async () => {
      localStorage.removeItem("lk-user-choices");
      const currentTime = moment().format('MM/DD/YYYY, HH:mm:ss A');
      try {
        const titleResponse = await fetch("https://bds-api.staging.beam.live/aggregateBds", {
          method: "POST",
          body: JSON.stringify({
            select: {
              index: {
                searchIndex: "beamdevlive:page:title",
                searchQuery: "*",
                searchOptions: "TIMEOUT 10000"
              },
              list: {
                objectIds: [props.roomName]
              },
              load: {
                title: "$..title"
              }
            },
            groupBy: {
              properties: [],
              reducers: []
            },
            apply: {
              expression: "",
              name: ""
            },
            result: {
              pagination: {
                offset: 0,
                limit: 1
              }
            },
            filter: {
              expression: ""
            },
            anyStatus: false
          }),
          headers: {
            "Content-Type": "application/json",
          },
        });
        
        console.log("Title Response: ", titleResponse.status)
        if (titleResponse.status == 201) {
          const titleData = await titleResponse.json();
          if (titleData["data"].length > 0) {
            setRoomTitle(titleData["data"][0]["title"])
          } else {
            setRoomTitle(currentTime)
          }
        } else {
          setRoomTitle(currentTime)
        }
      } catch (error) {
        console.error("API Request Failed in Room Title:", error);
        setRoomTitle(currentTime)
      }

      try {
        const userResponse = await fetch("https://bds-api.staging.beam.live/aggregateBds", {
          method: "POST",
          body: JSON.stringify({
            "select": {
              "index": {
                "searchIndex": "beamdevlive:userNameIndex",
                "searchQuery": `@partitions:{'org\\:${props.partitionId?.replace("-", "\\-")}'}`,
                "searchOptions": "TIMEOUT 10000"
              },
              "list": {
                "objectIds": [
                  props.userId
                ]
              },
              "load": {
                "profile": "$.persistent.static.live"
              }
            },
            "groupBy": {
              "properties": [],
              "reducers": []
            },
            "apply": {
              "expression": "",
              "name": ""
            },
            "result": {
              "pagination": {
                "offset": 0,
                "limit": 1
              }
            },
            "filter": {
              "expression": ""
            },
            "anyStatus": false
        }),
          headers: {
            "Content-Type": "application/json",
          },
        });
  
        console.log("Username Response: ", userResponse.status)
        if (userResponse.status == 201) {
          const userData = await userResponse.json();
          if (userData["data"].length > 0) {
            setRoomUser(`${userData["data"][0]["profile"]["profile"]["firstName"]} ${userData["data"][0]["profile"]["profile"]["lastName"]}`)
          } else {
            setRoomUser(defaultUsername)
          }
        } else {
          setRoomUser(defaultUsername)
        }        
      } catch (error) {
        console.error("API Request Failed in User Name:", error);
        setRoomUser(defaultUsername)
      }
    };
  
    fetchData();
  }, [props.roomName]);

  if (!roomUser) {
    return (
      <main data-lk-theme="default" style={{ height: '100%' }}>
        <header>
          <img src="/images/logo.svg" alt="Beam Meet" />
        </header>
        <div style={{ display: 'grid', placeItems: 'center', height: '100%' }}>
          <div className="spinner"></div>
        </div>
      </main>
    );
  }

  return (
    <main data-lk-theme="default" style={{ height: '100%' }}>
      {connectionDetails === undefined || preJoinChoices === undefined ? (
        <>
          <header>
            <img src="/images/logo.svg" alt="Beam Meet" />
          </header>
          <div style={{ display: 'grid', placeItems: 'center', height: '100%' }} className={props.viewer === 'true'? "viewer-container": "joiner-container"}>
            <div className="lk-prejoin-container">
            <p className="join-datetime">Join {roomTitle}</p>
            <PreJoin
              defaults={{
                ...preJoinDefaults,
                videoEnabled: props.viewer !== 'true',
                audioEnabled: props.viewer !== 'true',
              }}
              onSubmit={handlePreJoinSubmit}
              onError={handlePreJoinError}
              joinLabel={props.viewer === 'true'? "Watch Now": "Join Now"}
            >
            </PreJoin>
            </div>
          </div>
        </>
      ) : (
        <VideoConferenceComponent
          connectionDetails={connectionDetails}
          userChoices={preJoinChoices}
          options={{ codec: props.codec, hq: props.hq }}
          viewer={props.viewer === 'true'}
        />
      )}
    </main>
  );
}

function VideoConferenceComponent(props: {
  userChoices: LocalUserChoices;
  connectionDetails: ConnectionDetails;
  options: {
    hq: boolean;
    codec: VideoCodec;
  };
  viewer?: boolean;
}) {
  const e2eePassphrase =
    typeof window !== 'undefined' && decodePassphrase(location.hash.substring(1));

  const worker =
    typeof window !== 'undefined' &&
    e2eePassphrase &&
    new Worker(new URL('livekit-client/e2ee-worker', import.meta.url));
  const e2eeEnabled = !!(e2eePassphrase && worker);
  const keyProvider = new ExternalE2EEKeyProvider();
  const [e2eeSetupComplete, setE2eeSetupComplete] = React.useState(false);

  const roomOptions = React.useMemo((): RoomOptions => {
    let videoCodec: VideoCodec | undefined = props.options.codec ? props.options.codec : 'vp9';
    if (e2eeEnabled && (videoCodec === 'av1' || videoCodec === 'vp9')) {
      videoCodec = undefined;
    }
    return {
      videoCaptureDefaults: {
        deviceId: props.userChoices.videoDeviceId ?? undefined,
        resolution: props.options.hq ? VideoPresets.h2160 : VideoPresets.h720,
      },
      publishDefaults: {
        dtx: false,
        videoSimulcastLayers: props.options.hq
          ? [VideoPresets.h1080, VideoPresets.h720]
          : [VideoPresets.h540, VideoPresets.h216],
        red: !e2eeEnabled,
        videoCodec,
      },
      audioCaptureDefaults: {
        deviceId: props.userChoices.audioDeviceId ?? undefined,
      },
      adaptiveStream: { pixelDensity: 'screen' },
      dynacast: true,
      e2ee: e2eeEnabled
        ? {
            keyProvider,
            worker,
          }
        : undefined,
    };
  }, [props.userChoices, props.options.hq, props.options.codec]);

  const room = React.useMemo(() => new Room(roomOptions), []);

  React.useEffect(() => {
    if (e2eeEnabled) {
      keyProvider
        .setKey(decodePassphrase(e2eePassphrase))
        .then(() => {
          room.setE2EEEnabled(true).catch((e) => {
            if (e instanceof DeviceUnsupportedError) {
              alert(
                `You're trying to join an encrypted meeting, but your browser does not support it. Please update it to the latest version and try again.`,
              );
              console.error(e);
            } else {
              throw e;
            }
          });
        })
        .then(() => setE2eeSetupComplete(true));
    } else {
      setE2eeSetupComplete(true);
    }
  }, [e2eeEnabled, room, e2eePassphrase]);

  const connectOptions = React.useMemo((): RoomConnectOptions => {
    return {
      autoSubscribe: true,
    };
  }, []);

  const router = useRouter();
  const handleOnLeave = React.useCallback(() => window.location.reload(), [router]);
  const handleError = React.useCallback((error: Error) => {
    console.error(error);
    // alert(`Encountered an unexpected error, check the console logs for details: ${error.message}`);
  }, []);
  const handleEncryptionError = React.useCallback((error: Error) => {
    console.error(error);
    alert(
      `Encountered an unexpected encryption error, check the console logs for details: ${error.message}`,
    );
  }, []);

  return (
    <>
      <LiveKitRoom
        connect={e2eeSetupComplete}
        room={room}
        token={props.connectionDetails.participantToken}
        serverUrl={props.connectionDetails.serverUrl}
        connectOptions={connectOptions}
        video={props.userChoices.videoEnabled}
        audio={props.userChoices.audioEnabled}
        onDisconnected={handleOnLeave}
        onEncryptionError={handleEncryptionError}
        onError={handleError}      
      >
        <CustomVideoConference
          chatMessageFormatter={formatChatMessageLinks}
          SettingsComponent={SHOW_SETTINGS_MENU ? SettingsMenu : undefined}
          viewer={props.viewer}
        />
        <DebugMode />
        <RecordingIndicator />
      </LiveKitRoom>
    </>
  );
}
